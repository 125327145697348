const ENV = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
};

const LoadingStatus = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

const StorageKey = {
  authUser: '@auth:user',
};

const ROUTES = {
  ROOT_PATH: '/',
  LOGIN_PATH: '/signin',
  DASHBOARD_PATH: '/dashboard',
  KYC: {
    INDEX: '/kycs',
    DETAIL: '/kycs/:id'
  },
  TRANSACTION: {
    INDEX: '/transactions',
    DETAIL: '/transactions/:id'
  },
  USERS: {
    INDEX: '/users',
    EDIT: '/users/:id/edit',
  },
  GROUPS: {
    INDEX: '/groups',
    NEW: '/groups/new',
    EDIT: '/groups/:id/edit',
  }
};

const DEFAULT_CURRENCY = 'INR';

export {
  ENV,
  ROUTES,
  LoadingStatus,
  StorageKey,
  DEFAULT_CURRENCY
};
