import React, { useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Modal,
  Row,
  Space,
  Table,
  Tag,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { GroupService, UserService } from '@/services';
import { ROUTES } from '@/constants';
import { useBeforeRender } from '@/utils';
import { hasPermission } from '@/utils/permission';
import { PERMISSION } from '@/constants/permission';

function EditContainer() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({ active: true });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupsPermisison, setGroupsPermisison] = useState([]);
  const [groupSelected, setGroupSelected] = useState([]);

  const fetchGroups = async () => {
    const { success, data } = await GroupService.getGroups({ pageSize: 100 });
    if (success) {
      setGroupsPermisison(data || []);
    }
  };

  const fetchData = async () => {
    const { success, data } = await UserService.getUser({ id });
    if (success) {
      setUserData(data);
      setGroupSelected(data?.groups?.map(item => item.id));
    }
    setLoading(false);
  };

  const onBack = () => {
    history.push(ROUTES.USERS.INDEX);
  };

  useBeforeRender(() => {
    fetchData();
    fetchGroups();
  }, []);

  const renderStatus = (_, record) => {
    const status = record.active ? 'active' : 'failed';
    const text = record.active ? 'Active' : 'Deactivated';

    return (
      <Tag
        className={`status-${status}`}
      >
        {text}
      </Tag>
    );
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Group Permission',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: renderStatus,
    },
  ];

  const handleUpdateGroupPermission = async () => {
    const { success, data } = await UserService.updateGroups({ id, groupIds: groupSelected });

    if (success) {
      setUserData(data);
      setIsModalOpen(false);
    }
  };

  const handleEditPermission = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setGroupSelected(userData?.groups?.map(item => item.id) || []);
  };

  const onChangePermission = (dataSelected) => {
    setGroupSelected(dataSelected);
  };

  const renderCardExtra = () => {
    if (!hasPermission(PERMISSION.USER_WRITE)) {
      return null;
    }
    return (
      <Button
        type='primary'
        onClick={handleEditPermission}
      >
        {userData?.groups && userData.groups.length > 0 ? 'Update Permission' : 'Add Permission'}
      </Button>
    );
  };

  const selectAll = (e) => {
    e.preventDefault();
    setGroupSelected(() => groupsPermisison.map(group => group.id));
  };

  const selectNone = (e) => {
    e.preventDefault();
    setGroupSelected([]);
  };

  return (
    <>
      <Button shape="round" onClick={onBack}>
        Back
      </Button>
      <Card
        className='mt-20'
        title={(userData.name || userData.email) ? `Manage User - ${userData.name || userData.email}` : null}
        extra={renderCardExtra()}
      >
        <Table
          columns={columns}
          dataSource={userData?.groups || []}
          pagination={false}
          loading={loading}
          rowKey={record => record.id}
          scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
        />
      </Card>
      <Modal
        centered
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        title="Groups Permission"
        visible={isModalOpen}
        onOk={handleUpdateGroupPermission}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleUpdateGroupPermission}
          >
            Submit
          </Button>
        ]}
      >
        <Row justify='end' gutter={[8, 8]}>
          <Col>
            <Space
              size='small'
            >
              <a href='##' onClick={selectAll}> Select All </a>
              <span>|</span>
              <a href='##' onClick={selectNone}> Select None </a>
            </Space>
          </Col>

          <Col>
            <Checkbox.Group
              style={{
                width: '100%',
              }}
              value={groupSelected}
              onChange={onChangePermission}
            >
              <Row
                gutter={[16, 16]}
              >
                {
                  groupsPermisison.map(group => (
                    <Col
                      span={24}
                      key={group.id}
                    >
                      <Checkbox
                        value={group.id}
                      >
                        {group.active && <span>{group.name}</span>}
                        {!group.active && <span style={{color: '#C62937'}}>{group.name}(Deactivated)</span>}
                      </Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default EditContainer;
