import { StorageKey } from '@/constants';
import camelcaseKeys from 'camelcase-keys';


export function getAuthUser() {
  try {
    const user = localStorage.getItem(StorageKey.authUser);
    return user !== 'undefined' ? JSON.parse(localStorage.getItem(StorageKey.authUser)) : null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function setAuthUser(user) {
  localStorage.setItem(StorageKey.authUser, JSON.stringify(camelcaseKeys(user)));
}

export function removeAuthUser() {
  localStorage.removeItem(StorageKey.authUser);
}

export function getLocalRefreshToken() {
  const user = JSON.parse(localStorage.getItem(StorageKey.authUser));
  return user?.refreshToken;
};

export function getLocalAccessToken() {
  const user = JSON.parse(localStorage.getItem(StorageKey.authUser));
  return user?.accessToken;
};

export function updateLocalAccessToken(token) {
  if (token) {
    const user = JSON.parse(localStorage.getItem(StorageKey.authUser));
    user.accessToken = token;
    localStorage.setItem(StorageKey.authUser, JSON.stringify(user));
  }
};
