import React from 'react';
import {
  Card,
  Button
} from 'antd';
import { useHistory } from 'react-router-dom';

import GroupForm from '@/components/Group/Form';
import { GroupService } from '@/services';
import { ROUTES } from '@/constants';

function NewGroupContainer() {
  const history = useHistory();
  const formData = { active: true };
  const handleOnFinish = async values => {
    const { success } = await GroupService.createGroup(values);
    if (success) {
      history.push(ROUTES.GROUPS.INDEX);
    }
  };

  const onBack = () => {
    history.push(ROUTES.GROUPS.INDEX);
  };
  return (
    <>
      <Button shape="round" onClick={onBack}>
        Back
      </Button>
      <Card className='mt-10' title='New Group'>
        <GroupForm
          handleOnFinish={handleOnFinish}
          formData={formData}
        />
      </Card >
    </>

  );
}

export default NewGroupContainer;
