import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import {
  Form,
  Modal,
  Row,
  Col,
  Button,
  Input,
  Select
} from 'antd';
import {
  uniqueId
} from 'lodash';

import './index.scss';

const { Option } = Select;

function ModalUpdate({
  isModalOpen,
  setIsModalOpen,
  handleUpdate,
  fields,
  dataForm
}) {
  const [formInstance] = Form.useForm();

  const handleOnFinish = (values) => {
    handleUpdate(values);
  };

  const renderField = (field) => {
    switch (field.typeInput) {
      case 'string':
        return renderStringField(field);
      case 'select':
        return renderSelectField(field);
      case 'select_multiple':
        return renderSelectMultipleField(field);
      default:
        return renderStringField(field);
    }
  };

  const renderStringField = ({
    label,
    name,
    placeholder,
    col,
  }) => (
    <Col key={uniqueId(name)} span={col || '12'}>
      {label && (<label htmlFor='#'>{label}</label>)}
      <Form.Item
        label=""
        name={name}
        allowClear
      >
        <Input
          name={name}
          placeholder={placeholder}
        />
      </Form.Item>
    </Col>
  );

  const renderSelectField = ({
    label,
    name,
    placeholder,
    col,
    options
  }) => (
    <Col key={uniqueId(name)} span={col || '12'}>
      {label && (<label htmlFor='#'>{label}</label>)}
      <Form.Item
        label=''
        name={name}
      >
        <Select
          name={name}
          placeholder={placeholder}
          allowClear
        >
          {options.map(
            ({ label: labelOpt, value }) => <Option key={uniqueId(value)} value={value}>{labelOpt}</Option>
          )}
        </Select>
      </Form.Item>
    </Col>
  );

  const renderSelectMultipleField = ({
    label,
    name,
    placeholder,
    col,
    options
  }) => (
    <Col key={uniqueId(name)} span={col || '12'}>
      {label && (<label htmlFor='#'>{label}</label>)}
      <Form.Item
        label=''
        name={name}
      >
        <Select
          mode="multiple"
          name={name}
          placeholder={placeholder}
          allowClear
        >
          {options.map(
            ({ label: labelOpt, value }) => <Option key={uniqueId(value)} value={value}>{labelOpt}</Option>
          )}
        </Select>
      </Form.Item>
    </Col>
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    formInstance.setFieldsValue(dataForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  return (
    <Modal
      centered
      title='Update Transaction'
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className='modal-update-form'
    >
      <Form
        form={formInstance}
        name='basic'
        autoComplete='off'
        layout='horizontal'
        onFinish={handleOnFinish}
        initialValues={dataForm}
      >
        <Row gutter={[12, 0]}>
          {
            fields.map(renderField)
          }
        </Row>
        <Row className='flex-center'>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            shape="round"
          >
            Update
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

ModalUpdate.propTypes = {
  isModalOpen: Proptypes.bool.isRequired,
  setIsModalOpen: Proptypes.func.isRequired,
  handleUpdate: Proptypes.func.isRequired,
  fields: Proptypes.arrayOf(Proptypes.object).isRequired,
  dataForm: Proptypes.object
};

export default ModalUpdate;
