export const OPTS_STATUS_TRANSACTION = [
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'CAPTURED', label: 'Captured' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'PAYOUT', label: 'Payout' },
  { value: 'PAID', label: 'Paid' },
];

export const OPTS_REF_TRANSACTION = [
  { value: 'PaymentPage', label: 'Payment Page' },
  { value: 'PaymentLink', label: 'Payment Link' },
  { value: 'PaymentButton', label: 'Payment Button' }
];
