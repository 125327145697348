import React from 'react';

import TransactionContainer from '@/containers/Transaction';

function TransactionsPage() {
  return (
    <TransactionContainer />
  );
}

export default TransactionsPage;
