import React from 'react';
import PropTypes from 'prop-types';
import {
  Space,
  Table,
  Button,
  Col
} from 'antd';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { hasPermission } from '@/utils/permission';
import { PERMISSION } from '@/constants/permission';


function List({
  data,
  loading,
  pagination,
  onTableChange,
}) {
  const history = useHistory();
  const openEdit = ({ id }) => {
    history.push(`${ROUTES.USERS.INDEX}/${id}/edit`);
  };

  const renderGroup = (text) => (
    <Col dangerouslySetInnerHTML={{ __html: text }} />
  );

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 150,
      fixed: 'left'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Group',
      dataIndex: 'groups',
      key: 'groups',
      width: 200,
      render: renderGroup,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
    }
  ];

  if (hasPermission(PERMISSION.USER_WRITE)) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 130,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => openEdit({ id: record.id })}
          >
            Manage
          </Button>
        </Space>
      )
    });
  }


  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={onTableChange}
      rowKey={record => record.id}
      scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
    />
  );
}

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ),
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  onTableChange: PropTypes.func,
};

export default List;
