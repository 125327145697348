import React from 'react';

import EditContainer from '@/containers/Users/edit';
import { useCheckAccessBefore } from '@/utils';
import { PERMISSION } from '@/constants/permission';

function EditPage() {
  useCheckAccessBefore(PERMISSION.USER_WRITE);
  return (
    <EditContainer />
  );
}

export default EditPage;
