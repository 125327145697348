import React from 'react';

import TransactionDetail from '@/containers/Transaction/detail';

function TransactionDetailPage() {
  return (
    <TransactionDetail />
  );
}

export default TransactionDetailPage;
