import React from 'react';
import NewGroupContainer from '@/containers/Groups/new';
import { useCheckAccessBefore } from '@/utils';
import { PERMISSION } from '@/constants/permission';

function NewGroupPage() {
  useCheckAccessBefore(PERMISSION.GROUP_WRITE);

  return (
    <NewGroupContainer />
  );
}

export default NewGroupPage;
