import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Button, Modal, Spin } from 'antd';

import DragUpload from '@/components/DraggerUpload';
import { TransactionService } from '@/services';

function ModalMassUpdate({
  isModalOpen,
  setIsModalOpen,
  handleSuccessUpdate
}) {
  const [hasError, setHasError] = useState(false);
  const [dataError, setDataError] = useState();
  const [loading, setLoading] = useState(false);
  const [fileUpdate, setFileUpdate] = useState();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const downloadError = () => {
    TransactionService.downloadCSV({ csvStr: dataError, nameFile: 'Reason Error Mass Update' });
  };

  const handleOk = async () => {
    setHasError(false);
    setDataError();
    setLoading(true);
    if (fileUpdate?.status === 'done' && fileUpdate?.originFileObj) {
      const file = fileUpdate?.originFileObj;
      const { success, status, data } = await TransactionService.uploadMassUpdateTransaction({ file });
      if (success) {
        setLoading(false);
        setIsModalOpen(false);
        handleSuccessUpdate();
        return;
      }
      setHasError(true);
      if (status === 406) {
        setDataError(data);
      }
    }
    setLoading(false);
  };

  const handleDownloadTemplateFile = (e) => {
    e.preventDefault();
    TransactionService.getMassTemplate();
  };


  useEffect(() => {
    if (isModalOpen) {
      setFileUpdate(null);
      setHasError(false);
      setDataError();
    };
  }, [isModalOpen]);

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title="Mass Update Modal"
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!fileUpdate}
          loading={loading}
          onClick={handleOk}
        >
          Submit
        </Button>
      ]}
    >
      <Spin spinning={loading}>
        <p>Download template: <a href="##" onClick={handleDownloadTemplateFile}>File Expample</a></p>
        <DragUpload setFileValue={setFileUpdate} fileValue={fileUpdate} />
        {hasError && dataError && (
          <p className='text-danger mt-10'>
            Mass Update Transactions Failed.
            Please download
            <a className='text-danger text-underline mx-4 fw-bold' onClick={downloadError} href='##'>file</a>
            error detail.
          </p>
        )}
        {
          hasError && !dataError && (
            <p className='text-danger mt-10'>
              Mass Update Transactions Failed. Please check format of your file before upload.
            </p>
          )
        }
      </Spin>
    </Modal>
  );
};

ModalMassUpdate.propTypes = {
  isModalOpen: Proptypes.bool,
  setIsModalOpen: Proptypes.func.isRequired,
  handleSuccessUpdate: Proptypes.func.isRequired
};

export default ModalMassUpdate;
