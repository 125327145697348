import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  Menu,
  Layout,
} from 'antd';
import {
  useHistory,
  useLocation
} from 'react-router-dom';

import HeaderComponent from '@/components/Header';

import './index.scss';
import { UserService } from '@/services';
import { useBeforeRender } from '@/utils';
import { renderMenus } from '@/utils/permission';

const { Content, Sider, Footer } = Layout;

function AuthLayout({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const handleOnClickMenu = ({ key }) => {
    history.replace(key);
  };
  let selectedKeys = location.pathname;
  if (selectedKeys.includes('/kycs')) {
    selectedKeys = '/kycs';
  }

  const fetchProfile = async () => {
    const { success, data } = await UserService.getProfile();
    if (success) {
      const menus = renderMenus({ permissionsUser: data.permissions, isSuperadmin: data.isSuperadmin });
      // localStorage.setItem()
      setMenuItems(menus);
    };
  };

  useBeforeRender(() => fetchProfile(), []);

  return (
    <Layout
      className="auth-layout"
    >
      <Sider
        width={240}
        collapsible
        trigger={React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: () => setCollapsed(!collapsed),
        })}
        collapsed={collapsed}
        className="auth-layout__sider-content"
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          items={menuItems}
          selectedKeys={[selectedKeys]}
          onClick={handleOnClickMenu}
        />
      </Sider>
      <Layout className="site-layout">
        <HeaderComponent
          className="auth-layout__header"
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Content
          className="auth-layout__main-content-container"
        >
          {children}
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          {`© ${new Date().getFullYear()} RadicalPay. All right reserved.`}
        </Footer>
      </Layout>
    </Layout>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element,
};

export default AuthLayout;
