import React from 'react';
import {
  UserOutlined,
  PieChartOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

import { ROUTES } from '@/constants';
import { PERMISSION } from '@/constants/permission';
import { getAuthUser } from './localStorageUtil';

export const renderMenus = ({ permissionsUser, isSuperadmin }) => {
  let menus = [];
  if (!isSuperadmin) {
    menuItems.forEach(item => {
      if (item.permissions.length === 0) {
        menus.push(item.menu);
      } else {
        item.permissions.every(per => {
          if (hasPermissionMenu(per, permissionsUser)) {
            menus.push(item.menu);
            return false;
          }
          return true;
        });
      }
    });
  } else {
    menus = menuItems.map(item => item.menu);
  }

  return menus;
};

const hasPermissionMenu = (permissionMenu, permissionsUser) => permissionsUser.includes(permissionMenu);

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const menuItems = [
  {
    menu: getItem('Dashboard', '/dashboard', <PieChartOutlined />),
    permissions: []
  },
  {
    menu: getItem('KYC', '/kycs', <UserOutlined />),
    permissions: [PERMISSION.KYC_READ]
  },
  {
    menu: getItem('Transactions', ROUTES.TRANSACTION.INDEX, <UserOutlined />),
    permissions: [PERMISSION.TRANSACTION_READ]
  },
  {
    menu: getItem('Users', '/users', <UsergroupAddOutlined />),
    permissions: [PERMISSION.USER_READ]
  },
  {
    menu: getItem('Groups', '/groups', <ApartmentOutlined />),
    permissions: [PERMISSION.GROUP_READ]
  }
];

export const hasPermission = (permission) => {
  const user = getAuthUser();
  return user.isSuperadmin || user?.permissions?.includes(permission);
};
