import React from 'react';
import GroupsContainer from '@/containers/Groups/list';

function GroupsPage() {
  return (
    <GroupsContainer />
  );
}

export default GroupsPage;
