export const kycApi = {
  getListKycs: '/kycs',
  reviewKyc: '/kycs/:id/review',
  detail: '/kycs/:id',
  getFile: '/files/:id'
};

export const authApi = {
  oauthLogin: '/auth/login',
  refreshToken: ''
};

export const transactionAPI = {
  index: '/transactions',
  detail: '/transactions/:id',
  exportCSV: '/transactions/export-csv',
  updateStatus: '/transactions/:id/update-status',
  massUpdate: '/transactions/mass-update',
  massTemplate: '/transactions/mass-update-template',
};

export const usersAPI = {
  index: '/users',
  detail: '/users/:id',
  addGroup: '/users/:id/groups',
  profile: '/users/profile'
};

export const groupsApi = {
  index: '/groups',
  create: '/groups',
  update: '/groups/:id',
  detail: '/groups/:id'
};
