import React, { useEffect, useState } from 'react';
import {
  Typography,
} from 'antd';
import moment from 'moment';

import UserList from '@/components/Users/List';
import { UserService } from '@/services';
import FilterComponent from '@/components/shared/Filter';

const { Title } = Typography;

function UsersContainer() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  });
  const [fitlerData, setFilterData] = useState({});

  const fetchData = async (params = {}) => {
    setLoading(true);
    UserService.getUsers(params).then(results => {
      const { success, data: users, pagination: pagi } = results;
      if (success) {
        setPagination({
          ...pagination,
          total: pagi?.total,
          pageSize: pagi?.limit,
          current: pagi?.currentPage,
        });
        const list = users?.map(obj => ({
          ...obj,
          groups: obj.groups?.map(group => group.name).join('<br/>') || '-',
          createdAt: moment(new Date(obj.createdAt)).format('DD/MM/YY, hh:mma'),
        }));
        setData(list || []);
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (newPagination) => {
    fetchData({
      ...fitlerData,
      ...newPagination,
    });
  };

  const handleOnFilter = (values) => {
    fetchData({
      ...fitlerData,
      ...pagination,
      ...values
    });
    setFilterData({ ...fitlerData, ...pagination, ...values });
  };

  const fields = [
    {
      label: 'Email',
      name: 'email',
      typeInput: 'email',
      placeholder: 'Email',
      col: '6'
    },
  ];

  return (
    <>
      <Title level={3}>Management</Title>
      <FilterComponent
        fields={fields}
        handleUpdate={handleOnFilter}
      />
      <UserList
        data={data}
        setData={setData}
        loading={loading}
        pagination={pagination}
        onTableChange={handleTableChange}
      />
    </>
  );
}

export default UsersContainer;
