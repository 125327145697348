/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Input
} from 'antd';
import PropTypes from 'prop-types';

import { OPTS_PERMISSION } from '@/constants/permission';

const { Option } = Select;

function GroupForm({
  handleOnFinish,
  formData
}) {
  const optionsActive = [
    { label: 'Active', value: true },
    { label: 'Deactivate', value: false }
  ];
  const [formInstance] = Form.useForm();

  useEffect(() => {
    if (formData) {
      formInstance.setFieldsValue(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  return (
    <Form
      form={formInstance}
      name="basic"
      autoComplete="off"
      layout="horizontal"
      onFinish={handleOnFinish}
      initialValues={formData}
    >
      <Row>
        <Col span={12}>
          <label htmlFor="#">
            Name
          </label>
          <Form.Item
            label=""
            name='name'
            rules={[
              { required: true, message: 'Name is required' },
            ]}
          >
            <Input
              required
              size="large"
              name='name'
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <label htmlFor="#">
            Status
          </label>
          <Form.Item
            label=""
            name="active"
          >
            <Select
              style={{
                width: '100%',
              }}
              size="large"
            >
              {optionsActive.map(
                ({ label, value }) => <Option key={value} value={value}>{label}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <label htmlFor="#">
            Permission
          </label>
          <Form.Item
            label=""
            name="permissions"
            rules={[
              { required: true, message: 'Permissions is required' },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              size="large"
            >
              {OPTS_PERMISSION.map(
                ({ label, value }) => <Option key={value} value={value}>{label}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Button
        size="large"
        type="primary"
        htmlType="submit"
      >
        {formData.id ? 'Update' : 'Create'}
      </Button>
    </Form>
  );
}

GroupForm.propTypes = {
  handleOnFinish: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired
};

export default GroupForm;
