import React from 'react';
import { Typography } from 'antd';

import './index.scss';

const { Title } = Typography;

function DashboardPage() {
  return (
    <div className="dashboard-page">
      <div className="container">
        <div className="intro">
          <Title level={1} className="intro__title">
            DashboadPage
          </Title>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
