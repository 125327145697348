import React, { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  Spin,
} from 'antd';

import { parseTransactionsDetail } from '@/utils/transactions';
import { useBeforeRender } from '@/utils';
import TransactionDetailComponent from '@/components/Transaction/Detail';
import { ROUTES } from '@/constants';
import { TransactionService } from '@/services';

function TransactionDetail() {
  const { id } = useParams();
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const { success, data } = await TransactionService.getTransactionsDetail({ id });
    if (success) {
      setRecord(parseTransactionsDetail(data));
      setLoading(false);
    }
  };

  useBeforeRender(() => fetchData(), []);

  const onBack = () => {
    window.location.href = ROUTES.TRANSACTION.INDEX;
  };

  return (
    <Spin spinning={loading}>
      <TransactionDetailComponent
        data={record}
        onBack={onBack}
      />
    </Spin>
  );
}

export default TransactionDetail;
