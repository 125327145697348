import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Layout,
  Button,
} from 'antd';
import { useDispatch } from 'react-redux';

import { logout } from '@/store/auth/authSlice';

import './index.scss';

const { Header } = Layout;

function HeaderComponent({
  className,
}) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleLogoutConfirm = () => {
    setLoadingLogout(true);
    setTimeout(() => {
      dispatch(logout());
    }, 1000);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Header
      className={`header-container ${className}`}
      id="header-container"
    >
      <div className="wrapper">
        <div className="btn-wrapper">
          <Button
            type="link"
            onClick={showModal}
          >
            Logout
          </Button>
        </div>
      </div>

      <Modal
        centered
        destroyOnClose
        closable={false}
        keyboard={false}
        okText="Log out"
        onOk={handleLogoutConfirm}
        maskClosable={false}
        onCancel={handleCancel}
        visible={isModalVisible}
        confirmLoading={loadingLogout}
        title="Are you sure want to log out?"
        getContainer={() => document.getElementById('header-container')}
        cancelButtonProps={{
          disabled: loadingLogout,
        }}
      />
    </Header>
  );
}

HeaderComponent.propTypes = {
  className: PropTypes.string,
};

export default HeaderComponent;
