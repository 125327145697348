import React, { useState } from 'react';
import {
  Typography,
  Button,
  Spin,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

import Transaction from '@/components/Transaction';
import { TransactionService } from '@/services';
import { useBeforeRender } from '@/utils';
import { parseTransactionsToDataTable } from '@/utils/transactions';
import FilterComponent from '@/components/shared/Filter';
import { OPTS_REF_TRANSACTION, OPTS_STATUS_TRANSACTION } from '@/constants/transaction';
import ModalMassUpdate from '@/components/Transaction/ModalMassUpdate';
import { hasPermission } from '@/utils/permission';
import { PERMISSION } from '@/constants/permission';

const { Title } = Typography;

function TransactionContainer() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  });
  const [filter, setFilter] = useState({});

  const initData = async (params = {}) => {
    setLoading(true);
    const res = await TransactionService.getTransactions(params);
    setPagination({
      ...pagination,
      total: res.pagination?.total,
      pageSize: res.pagination?.limit,
      current: res.pagination?.currentPage,
    });
    setData(parseTransactionsToDataTable(res.data));
    setLoading(false);
  };

  const handleTableChange = (newPagination) => {
    initData({
      ...filter,
      ...newPagination,
    });
  };

  const handleOnFilter = (values) => {
    if (values.createdAt?.length > 0) {
      const [from, to] = values.createdAt;
      values.createdFrom = moment(`${from.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD hh:mm:ss').utc().format();
      values.createdTo = moment(`${to.format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD hh:mm:ss').utc().format();
    }
    delete values.createdAt;
    initData({
      ...filter,
      ...values
    });
    setFilter({ ...filter, ...values });
  };

  const exportCSV = async () => {
    setLoadingPage(true);
    await TransactionService.exportTransactions(filter);
    setLoadingPage(false);
  };

  const handleSuccessUpdate = () => {
    initData({});
  };

  useBeforeRender(() => initData({}), []);

  const onChangeStatus = async ({ status, id }) => {
    setLoading(true);
    const {
      success,
      data: newData
    } = await TransactionService.updateStatusTransaction({ id, status });
    if (success) {
      const objIndex = data.findIndex((obj => obj.id === newData.id));
      data[objIndex].status = newData.status;
      setData([...data]);
      setLoading(false);
      return true;
    }
    setLoading(false);
    return false;
  };
  const writeTransaction = hasPermission(PERMISSION.TRANSACTION_WRITE);

  const fields = [
    {
      label: 'Transaction ID',
      name: 'id',
      typeInput: 'string',
      placeholder: 'ID',
      col: '5'
    },
    {
      label: 'Status',
      name: 'status',
      typeInput: 'select',
      placeholder: 'Status',
      options: OPTS_STATUS_TRANSACTION,
      col: '5'
    },
    {
      label: 'Merchant ID',
      name: 'merchantId',
      typeInput: 'string',
      placeholder: 'Merchant ID',
      col: '5'
    },
    {
      label: 'Order ID',
      name: 'orderId',
      typeInput: 'string',
      placeholder: 'Order ID',
      col: '5'
    },
    {
      label: 'Ref ID',
      name: 'refId',
      typeInput: 'string',
      placeholder: 'Ref ID',
      col: '5'
    },
    {
      label: 'Ref Type',
      name: 'refType',
      typeInput: 'select',
      placeholder: 'Select Ref',
      options: OPTS_REF_TRANSACTION,
      col: '5'
    },
    {
      label: 'Created At',
      name: 'createdAt',
      typeInput: 'date-range',
      placeholder: 'Created At',
      col: '5'
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Spin spinning={loadingPage}>
      <div className='d-flex justify-content-between'>
        <Title level={3}>Transaction List</Title>
        {
          writeTransaction && (
            <Button onClick={openModal} icon={<UploadOutlined />}>Mass Update</Button>
          )
        }
      </div>
      <FilterComponent
        fields={fields}
        handleUpdate={handleOnFilter}
        exportCSV={exportCSV}
        hasExport
      />
      <Transaction
        loading={loading}
        data={data}
        onChangeStatus={onChangeStatus}
        pagination={pagination}
        onTableChange={handleTableChange}
      />
      {
        writeTransaction && (
          <ModalMassUpdate
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleSuccessUpdate={handleSuccessUpdate}
          />
        )
      }
    </Spin>
  );
}

export default TransactionContainer;
