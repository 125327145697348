import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import PropTypes from 'prop-types';

const { Dragger } = Upload;

function DraggerUpload({
  setFileValue,
  fileValue
}) {
  const [fileList, setFileList] = useState([]);
  const customRequest = async (event) => {
    event.onSuccess('ok');
  };
  const props = {
    name: 'file',
    accept: '.csv',
    onChange(info) {
      setFileList(info.fileList);
      if (info?.file?.status === 'done' && info?.file?.originFileObj) {
        setFileValue(info?.file);
      }
    },
    beforeUpload: async (file) => {
      const isCSV = file.type === 'text/csv';

      if (!isCSV) {
        message.error('You can only upload CSV file!');
      }
      return isCSV;
    },
    // showUploadList: false,
    customRequest,
    maxCount: 1
  };

  useEffect(() => {
    !fileValue && setFileList([]);
  }, [fileValue]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dragger fileList={fileList} {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for only csv file
      </p>
    </Dragger>
  );
};

DraggerUpload.propTypes = {
  setFileValue: PropTypes.func,
  fileValue: PropTypes.object
};

export default DraggerUpload;
