import React, { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { useBeforeRender } from '@/utils';
import KycDetailComponent from '@/components/Kyc/Detail';
import {
  Spin,
  Modal
} from 'antd';
import './detail.scss';
import PreviewPdf from '@/components/PreviewPdf';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RejectForm from '@/components/Kyc/RejectForm';
import { KycService } from '@/services';
import { KycRejectCodes, KycStatusesOptions } from '@/constants/kyc';
import { ROUTES } from '@/constants';
import parsePhoneNumber from 'libphonenumber-js';
import country from '@/utils/country';

const { confirm } = Modal;

function KycDetailContainer() {
  const { id } = useParams();
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const [urlPdf, setUrlPdf] = useState('');
  const [namePDF, setNamePDF] = useState('');
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const getCountry = code => country.find(item => item.code === code);

  const fetchData = async () => {
    const res = await KycService.getKycDetail({ id });
    const obj = KycStatusesOptions.find(item => item.value === res.status);
    const stateCode = getCountry(res.stateCode);
    const operationStateCode = getCountry(res.operationStateCode);
    const rejectReasonCode = KycRejectCodes.find(data => data.value === res.rejectReasonCode);
    setRecord({
      ...res,
      status: obj?.text || '',
      contactNumber: res.contactNumber ? parsePhoneNumber(`+${res.contactNumber}`).formatInternational() : '',
      stateCode: stateCode ? stateCode.name : res.stateCode,
      operationStateCode: operationStateCode ? operationStateCode.name : res.operationStateCode,
      businessType: res.businessType?.name,
      businessCategory: res.businessCategory?.name,
      isOperationSameAbove: res.isOperationSameAbove.toString(),
      rejectReasonCode: rejectReasonCode?.text || res.rejectReasonCode
    });
    setLoading(false);
  };

  const fetchFile = async (idFile) => {
    const file = await KycService.getFile({ id: idFile });
    setUrlPdf(file.url);
    setNamePDF(file.name);
    setVisible(true);
  };
  useBeforeRender(() => fetchData(), []);

  const [visibleReject, setVisibleReject] = useState(false);
  const [currentKycId, setCurrentKycId] = useState(null);

  const showConfirmAprove = (kycId) => {
    confirm({
      title: 'KYC Approval',
      content: 'Are you sure you want to approve KYC information?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        await handleApproveKyc(kycId);
      },
      centered: true,
    });
  };

  const handleApproveKyc = async (kycId) => {
    const res = await KycService.approveKyc({ id: kycId });

    if (res.status === 200) {
      window.location.reload();
    }
  };

  const handleRejectKyc = async (payload) => {
    const res = await KycService.rejectKyc(payload);

    if (res.status === 200) {
      window.location.reload();
    }

    return res;
  };

  const handleOpenRejectForm = (kycId) => {
    setVisibleReject(true);
    setCurrentKycId(kycId);
  };

  const handleCloseRejectForm = () => {
    setVisibleReject(false);
    setCurrentKycId(undefined);
  };

  const onBack = () => {
    window.location.href = ROUTES.KYC.INDEX;
  };

  return (
    <Spin spinning={loading}>
      <KycDetailComponent
        data={record}
        openFile={fetchFile}
        showConfirmAprove={showConfirmAprove}
        handleOpenRejectForm={handleOpenRejectForm}
        onBack={onBack}
      />
      <PreviewPdf
        urlPdf={urlPdf}
        name={namePDF}
        visible={visible}
        handleCancel={handleCancel}
      />
      {!!currentKycId &&
        <Modal
          centered
          title="Reject"
          footer={null}
          visible={visibleReject}
          onCancel={handleCloseRejectForm}
        >
          <RejectForm
            kycId={currentKycId}
            onRejectKyc={handleRejectKyc}
            handleCloseRejectForm={handleCloseRejectForm}
          />
        </Modal>
      }
    </Spin>
  );
}

export default KycDetailContainer;
