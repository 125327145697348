import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tag,
  Space,
  Table,
  Modal,
  Button,
} from 'antd';
import {
  uniqueId,
} from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RejectForm from '@/components/Kyc/RejectForm';
import { KycStatusesOptions, KycStatusesEnum } from '@/constants/kyc';
import { ROUTES } from '@/constants';
import { PERMISSION } from '@/constants/permission';
import { hasPermission } from '@/utils/permission';

const { confirm } = Modal;

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ),
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  onTableChange: PropTypes.func,
  handleRejectKyc: PropTypes.func,
  handleApproveKyc: PropTypes.func,
};

function List({
  data,
  loading,
  pagination,
  onTableChange,
  handleRejectKyc,
  handleApproveKyc,
}) {
  const [visible, setVisible] = useState(false);
  const [currentKycId, setCurrentKycId] = useState(null);

  const showConfirmAprove = (kycId) => {
    confirm({
      title: 'KYC Approval',
      content: 'Are you sure you want to approve KYC information?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        await handleApproveKyc(kycId);
      },
      centered: true,
    });
  };

  const handleOpenRejectForm = (kycId) => {
    setVisible(true);
    setCurrentKycId(kycId);
  };

  const writeKYC = hasPermission(PERMISSION.KYC_WRITE);

  const columns = [
    {
      title: 'Merchant Id',
      dataIndex: 'merchantId',
      key: 'merchantId',
      width: 150,
      fixed: 'left',
      render: (id, record) => (
        <a
          href={`${ROUTES.KYC.INDEX}/${record.id}`}
        >
          {id}
        </a>
      ),
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      width: 300,
    },
    {
      title: 'Contact Email',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      width: 300,
    },
    {
      title: 'Phone Number',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => {
        const obj = KycStatusesOptions.find(item => item.value === status);
        if (!obj) {
          return null;
        }

        return (
          <Tag
            key={uniqueId}
            className={`kyc-status-${obj?.text?.toLowerCase()}`}
          >
            {obj?.text}
          </Tag>
        );
      }
    },
    {
      title: 'Created Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 300,
    },
    {
      title: 'Updated Time',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 300,
    }
  ];

  if (writeKYC) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 210,
      render: (_, record) => {
        if (record.status === KycStatusesEnum.activated) {
          return null;
        }
        return (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => showConfirmAprove(record.id)}
            >
              Approve
            </Button>
            {
              record.status === KycStatusesEnum.submitted && (
                <Button
                  type="danger"
                  onClick={() => handleOpenRejectForm(record.id)}
                >
                  Reject
                </Button>
              )
            }
          </Space>
        );
      },
    });
  }

  const handleCloseRejectForm = () => {
    setVisible(false);
    setCurrentKycId(undefined);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={onTableChange}
        rowKey={record => record.id}
        scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
      />

      {/* Reject Form */}
      {!!currentKycId &&
        <Modal
          centered
          title="Reject"
          footer={null}
          visible={visible}
          onCancel={handleCloseRejectForm}
        >
          <RejectForm
            kycId={currentKycId}
            onRejectKyc={handleRejectKyc}
            handleCloseRejectForm={handleCloseRejectForm}
          />
        </Modal>
      }
    </>
  );
}

export default List;
