import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import googleLogo from '@/assets/images/google-logo.svg';

import './login.scss';

LoginPage.propTypes = {
  responseGoogle: PropTypes.func,
};

function LoginPage({
  responseGoogle,
}) {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  return (
    <div className='login-page'>
      <Row>
        <Col className='login-page__box'>
          <div className='login-page__box__container'>
            <div className='login-page__box__content'>
              <div className='login-page__box__content__title'>Welcome!</div>
              <div className='login-page__box__content__desc'>
                You have to sign in with RadicalPay email (example@radicapay.in) to access
              </div>
              <div className='login-page__box__content__via-social'>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_ID}
                  render={renderProps => (
                    <Button
                      onClick={() => renderProps.onClick()}
                      shape="round"
                      size='large'
                      className='btn btn-login-via-google'
                      icon={<img src={googleLogo} alt='google logo' width='22' height='22' />}
                    >Sign in with Google</Button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  isSignedIn={false}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

    </div>
  );
}

export default LoginPage;
