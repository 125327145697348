import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Select,
} from 'antd';

import { KycRejectCodes } from '@/constants/kyc';

const { Option } = Select;

RejectForm.propTypes = {
  kycId: PropTypes.string.isRequired,
  handleCloseRejectForm: PropTypes.func,
  onRejectKyc: PropTypes.func.isRequired,
};

function RejectForm({
  kycId,
  onRejectKyc,
  handleCloseRejectForm,
}) {
  const [formInstance] = Form.useForm();
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(false);

  const handleOnValuesChange = (changedValues, allValues) => {
    const fieldsValueKeys = Object.keys(allValues);
    const allFiledsHaveValue = fieldsValueKeys.every(key => !!allValues[key]);
    const isFormValid = allFiledsHaveValue && formInstance.getFieldsError().every(({ errors }) => errors.length === 0);
    setEnableBtnSubmit(isFormValid);
  };

  const hanldeOnFinish = async (values) => {
    console.log('Success:', values);
    const payload = {
      id: kycId,
      reason: values.reason,
      rejectedCode: values.rejectedCode,
    };

    const res = await onRejectKyc(payload);

    if (res.status === 200) {
      !!handleCloseRejectForm && handleCloseRejectForm();
    }
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={formInstance}
      onFinish={hanldeOnFinish}
      onValuesChange={handleOnValuesChange}
    >
      <Form.Item
        label="Reject Type"
        name="rejectedCode"
        rules={[
          {
            required: true,
            message: 'Please select the reject type!',
          },
        ]}
      >
        <Select
          size="large"
          placeholder="Select Reject Type"
        >
          {KycRejectCodes.length > 0 && KycRejectCodes.map(
            (({ value, text }) => <Option key={value} value={value}>{text}</Option>)
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label="Reason"
        name="reason"
        rules={[
          {
            required: true,
            message: 'Please input the reason!',
          },
          () => ({
            validator(_, value) {
              if (value && value.trim().length === 0) {
                return Promise.reject(new Error('The reason invalid'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Enter the reason"
        />
      </Form.Item>

      <Form.Item
        className="text-right"
      >
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!enableBtnSubmit}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default RejectForm;
