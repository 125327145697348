import { startCase } from 'lodash';
import { OPTS_STATUS_TRANSACTION } from '@/constants/transaction';
import { formatCurrency, parseDateTime } from '@/utils/formatter';

export const parseTransactionsToDataTable = list => list?.map(data => ({
  key: data.id,
  id: data.id,
  paymentId: data.id,
  orderId: data.orderId,
  amount: formatCurrency(Number(data.amount || 0) / 100),
  email: data.email || '--',
  contact: data.contactNumber ? `+${data.contactNumber}` : '--',
  createdOn: parseDateTime(data.createdAt),
  status: data.status,
  merchantId: data.merchantId
}));

const paymentMethodTransaction = {
  upi: 'UPI',
  credit_debit: 'Payment Card',
  qr: 'QR',
  netbanking: 'Net Banking',
  wallet: 'Wallet'
};

export const parseTransactionsDetail = data => ({
  id: data.id,
  amount: formatCurrency(Number(data.amount || 0) / 100),
  status: data.status,
  paymentMethod: paymentMethodTransaction[data.paymentMethod] || '--',
  createdAt: parseDateTime(data.createdAt),
  email: data.email || '--',
  contact: data.contactNumber ? `+${data.contactNumber}` : '--',
  totalFee: formatCurrency(0),
  radicalPayFee: formatCurrency(0),
  gstFee: formatCurrency(0),
  orderId: data.orderId,
  merchantId: data.merchantId,
  refId: data.refId || '--',
  refType: startCase(data.refType) || '--',
  settlementDetails: '--',
});

export const getStatusesCanChanged = status => {
  switch (status) {
    case 'CAPTURED':
      return OPTS_STATUS_TRANSACTION.filter(opt => opt.value === 'PAYOUT');
    case 'PAYOUT':
      return OPTS_STATUS_TRANSACTION.filter(opt => opt.value === 'PAID' || opt.value === 'CAPTURED');
    default:
      return [];
  }
};
