import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { ENV, ROUTES, StorageKey } from '@/constants';
import { authApi } from '@/constants/api';
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateLocalAccessToken,
} from '@/utils/localStorageUtil';

const http = axios.create({
  baseURL: ENV.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    const accessToken = getLocalAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

http.interceptors.response.use(
  (res) => res,
  async (error) => {
    console.clear();
    const originalConfig = error.config;
    if (originalConfig.url === authApi.refreshToken) {
      localStorage.removeItem(StorageKey.authUser);
      window.location.href = ROUTES.LOGIN_PATH;
    }
    if (originalConfig.url !== authApi.signIn && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig.retry) {
        originalConfig.retry = true;
        try {
          const payload = snakecaseKeys({ refreshToken: getLocalRefreshToken() });
          const rs = await http.post(authApi.refreshToken, payload);

          const { accessToken } = camelcaseKeys(rs.data);

          updateLocalAccessToken(accessToken);

          return http(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  }
);
export default http;
