import React from 'react';
import UserContainer from '@/containers/Users/list';

function UsersPage() {
  return (
    <UserContainer />
  );
}

export default UsersPage;
