import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
} from 'antd';
import moment from 'moment';

import GroupList from '@/components/Group/List';
import { GroupService } from '@/services';
import FilterComponent from '@/components/shared/Filter';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { PERMISSION, PERMISSION_NAME } from '@/constants/permission';
import { hasPermission } from '@/utils/permission';

const { Title } = Typography;

function UsersContainer() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  });
  const [fitlerData, setFilterData] = useState({});

  const fetchData = async (params = {}) => {
    setLoading(true);
    GroupService.getGroups(params).then(results => {
      const { success, data: groups, pagination: pagi } = results;
      if (success) {
        setPagination({
          ...pagination,
          total: pagi.total,
          pageSize: pagi.limit,
          current: pagi.currentPage,
        });
        const list = groups?.map(obj => ({
          ...obj,
          status: obj.active ? 'Active' : 'Deactivated',
          permissions: parsePermission(obj.permissions),
          createdAt: moment(new Date(obj.createdAt)).format('DD/MM/YY, hh:mma'),
        }));
        setData(list || []);
      }
      setLoading(false);
    });
  };

  const parsePermission = permissions => {
    if (permissions && permissions.length > 0) {
      const per = permissions.map(p => PERMISSION_NAME[p]);
      return per.join('<br/>');
    }
    return '-';
  };

  useEffect(() => {
    fetchData(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (newPagination) => {
    fetchData({
      ...fitlerData,
      ...newPagination,
    });
  };

  const handleOnFilter = (values) => {
    fetchData({
      ...fitlerData,
      ...pagination,
      ...values
    });
    setFilterData({ ...fitlerData, ...pagination, ...values });
  };

  const fields = [
    {
      label: 'Name',
      name: 'name',
      typeInput: 'string',
      placeholder: 'Name',
      col: '6'
    },
  ];

  const history = useHistory();
  const openNew = () => {
    history.push(ROUTES.GROUPS.NEW);
  };

  const writeGroup = hasPermission(PERMISSION.GROUP_WRITE);

  return (
    <>
      <div className='d-flex justify-content-between'>
        <Title level={3}>Group Permission</Title>
        {
          writeGroup && <Button onClick={openNew}>New Group</Button>
        }
      </div>
      <FilterComponent
        fields={fields}
        handleUpdate={handleOnFilter}
      />
      <GroupList
        data={data}
        setData={setData}
        loading={loading}
        pagination={pagination}
        onTableChange={handleTableChange}
      />
    </>
  );
}

export default UsersContainer;
