import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Tag,
  Space,
  Button
} from 'antd';
import { capitalize } from 'lodash';

import { ROUTES } from '@/constants';
import ModalUpdate from '@/components/shared/ModalUpdate';
import { getStatusesCanChanged } from '@/utils/transactions';
import { hasPermission } from '@/utils/permission';
import { PERMISSION } from '@/constants/permission';

function List({
  data,
  loading,
  pagination,
  onTableChange,
  onChangeStatus,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEdit, setIdEdit] = useState();
  const [formUpdate, setFormUpdate] = useState();
  const [currentStatus, setCurrentStatus] = useState();

  const renderStatus = (text) => {
    let status = '';
    switch (text) {
      case 'FAILED':
        status = 'failed';
        break;
      case 'CAPTURED':
        status = 'active';
        break;
      case 'PAYOUT':
        status = 'payout';
        break;
      case 'PAID':
        status = 'paid';
        break;
      default:
        status = 'pending';
        break;
    }
    return (
      <Tag
        className={`status-${status}`}
      >
        {capitalize(text)}
      </Tag>
    );
  };
  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 140,
      render: (id, record) => (
        <a
          href={`${ROUTES.TRANSACTION.INDEX}/${record.id}`}
        >
          {id}
        </a>
      ),
    },
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      key: 'orderId',
      width: 160,
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 120
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 150
    },
    {
      title: 'Contact',
      key: 'contact',
      dataIndex: 'contact',
      width: 150
    },
    {
      title: 'Created On',
      key: 'createdOn',
      dataIndex: 'createdOn',
      width: 180
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: renderStatus,
      align: 'right',
      width: 160,
    },
  ];

  if (hasPermission(PERMISSION.TRANSACTION_WRITE)) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: (_, record) => {
        const statues = getStatusesCanChanged(record.status);
        if (statues.length === 0) {
          return null;
        }
        return (
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => openUpdateModal({ ...record, statues, })}
            >
              Edit
            </Button>
          </Space>
        );
      },
    });
  }

  const handleUpdateStatus = async ({ status }) => {
    if (status === currentStatus) {
      setIsModalOpen(false);
      return;
    }
    const success = await onChangeStatus({ status, id: idEdit });
    if (success) {
      setIsModalOpen(false);
    }
  };

  const openUpdateModal = ({ id, statues, status }) => {
    setFormUpdate({ status });
    setOptsStatus(statues);
    setIdEdit(id);
    setCurrentStatus(status);
    setIsModalOpen(true);
  };

  const [optionsStatus, setOptsStatus] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (optionsStatus.length > 0) {
      setFields([{
        label: 'Status',
        name: 'status',
        typeInput: 'select',
        placeholder: 'Status',
        options: optionsStatus,
        col: '24'
      }]);
    }
  }, [optionsStatus]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={onTableChange}
        rowKey={record => record.id}
        scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
      />
      <ModalUpdate
        isModalOpen={isModalOpen}
        fields={fields}
        setIsModalOpen={setIsModalOpen}
        handleUpdate={handleUpdateStatus}
        dataForm={formUpdate}
      />
    </>
  );
}

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
};

export default List;
