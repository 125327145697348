import React from 'react';
import PropTypes from 'prop-types';
import {
  Space,
  Table,
  Button,
  Tag,
  Col
} from 'antd';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { hasPermission } from '@/utils/permission';
import { PERMISSION } from '@/constants/permission';

function List({
  data,
  loading,
  pagination,
  onTableChange,
}) {
  const history = useHistory();
  const openEdit = ({ id }) => {
    history.push(`${ROUTES.GROUPS.INDEX}/${id}/edit`);
  };
  const renderStatus = (text, record) => {
    const status = record.active ? 'active' : 'failed';
    return (
      <Tag
        className={`status-${status}`}
      >
        {text}
      </Tag>
    );
  };

  const renderPermission = (text) => (
    <Col dangerouslySetInnerHTML={{ __html: text }} />
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 160,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: renderPermission,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: renderStatus,
    }
  ];

  const writeGroup = hasPermission(PERMISSION.GROUP_WRITE);
  if (writeGroup) {
    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 130,
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => openEdit({ id: record.id })}
          >
            Edit
          </Button>
        </Space>
      ),
    });
  }
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={onTableChange}
      rowKey={record => record.id}
      scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
    />
  );
}

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.object,
  ),
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  onTableChange: PropTypes.func,
};

export default List;
