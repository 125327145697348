import React from 'react';
import KycDetailContainer from '@/containers/KycContainer/detail';

function KycDetailPage() {
  return (
    <KycDetailContainer />
  );
}

export default KycDetailPage;
