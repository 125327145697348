import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { selectAuth } from '@/store/auth/authSelector';
import { AuthService } from '@/services';
import Login from '@/components/Auth/Login';

function LoginPage() {
  const auth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);

  const responseGoogle = async response => {
    setLoading(true);
    if (response?.accessToken) {
      const res = await AuthService.oauthLogin({ ggAccessToken: response.accessToken });

      if (res.status !== 200) {
        setLoading(false);
      }
    }
  };

  if (auth.user) {
    return <Redirect to='/' />;
  }

  return (
    <Spin
      spinning={loading}
      indicator={
        <Loading3QuartersOutlined spin style={{ fontSize: 36, }} />
      }
    >
      <Login
        responseGoogle={responseGoogle}
      />
    </Spin >
  );
}

export default LoginPage;
