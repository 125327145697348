import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Button,
  Tag
} from 'antd';
import {
  uniqueId,
  startCase,
  capitalize
} from 'lodash';

import './detail.scss';

TransactionDetailComponent.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func
};

function TransactionDetailComponent({ data, onBack }) {
  const renderStatus = (text) => {
    let status = '';
    switch (text) {
      case 'FAILED':
        status = 'failed';
        break;
      case 'CAPTURED':
        status = 'active';
        break;
      default:
        status = 'pending';
        break;
    }
    return (
      <Tag
        className={`status-${status}`}
      >
        {capitalize(text)}
      </Tag>
    );
  };
  const renderRow = ({ label, value }) => (
    <React.Fragment key={uniqueId('col-transaction-')}>
      <Col xs={{ span: 12 }} xl={{ span: 6 }}>
        <p><b>{startCase(label)}: </b></p>
      </Col>
      <Col xs={{ span: 12 }} xl={{ span: 6 }}>
        {
          (label === 'Status') ? (
            renderStatus(value)
          ) : (<p>{value}</p>)
        }
      </Col>
    </React.Fragment>
  );

  const transactionInfo = {
    'id': 'ID',
    'status': 'Status',
    'amount': 'Amount',
    'paymentMethod': 'Payment Method',
    'email': 'Email',
    'contact': 'Contact',
    'totalFee': 'Total Fee',
    'radicalPayFee': 'Radical Pay Fee',
    'gstFee': 'GST Fee',
    'orderId': 'Order ID',
    'merchantId': 'Merchant ID',
    'refId': 'Ref ID',
    'refType': 'Ref Type',
    'createdAt': 'Created At'
  };

  return (
    <div className="site-card-border-less-wrapper">
      <Button shape="round" onClick={onBack}>
        Back
      </Button>
      <Card title="General Info" className='transactioon-detail__card'>
        <Row gutter={[16, 16]}>
          {
            Object.keys(transactionInfo).map(key =>
              renderRow({ label: transactionInfo[key], value: data[key] })
            )
          }
        </Row>
      </Card>
    </div>
  );
}

export default TransactionDetailComponent;
